<template>
    <el-container>
        <el-main>
            <el-card shadow="never">
                <el-form ref="form" label-width="100px">
                    <el-row :gutter="10" class="top_l">
                        <el-col :span="24" :xs="24" class="elel">
                            <div>
                                <el-form-item label="品类名称" style="margin: 0;">
                                    <el-input v-model="type" style="width: 300px;" clearable
                                        placeholder="请输入品类名称"></el-input>
                                </el-form-item>
                            </div>
                            <div style="margin-left: 20px;">
                                <el-button type="primary" @click="dialogAdd = true">新增</el-button>
                                <el-button type="primary" @click="jouShare">搜索</el-button>
                            </div>

                        </el-col>
                    </el-row>
                </el-form>
                <!-- table 表格 -->
                <el-table v-loading="serloading" :data="tableData" :header-cell-style="{ background: '#eef1f6' }"
                    style="width: 100%" border stripe>
                    <el-table-column label="产品品类">
                        <template v-slot="scope">
                            {{ scope.row }}
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="200" align="center">
                        <template v-slot="scope">
                            <el-button size="small" type="primary" plain @click="deteleClk(scope.row)">删除</el-button>
                        </template>
                    </el-table-column>
                    <template #empty>
                        <el-empty :description="emptyText" :image-size="100"></el-empty>
                    </template>
                </el-table>
                <!-- 分页 -->
                <div class="pagin">
                    <el-pagination :small="true" v-model:currentPage="currentPage" v-model:page-size="pageSize"
                        :page-sizes="[10, 20, 50, 100]" background layout="total, sizes, prev, pager, next, jumper"
                        :total="+totals" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
                </div>
                <!-- 新增 -->
                <el-dialog v-model="dialogAdd" title="新增品类" width="50%" :fullscreen="fullscreenshow">
                    <el-form :model="ruleForm" :rules="rules" ref="addruleForm" label-width="150px"
                        class="demo-ruleForm">
                        <el-row :gutter="20">
                            <el-col :span="12" :xs="24">
                                <el-form-item label="品类名称" prop="type">
                                    <el-input v-model="ruleForm.type" clearable placeholder="请输入品类名称"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                    <template #footer>
                        <span class="dialog-footer">
                            <el-button type="primary" @click="submitClick('addruleForm')">提交</el-button>
                            <el-button @click="resetClick('addruleForm')">重置</el-button>
                        </span>
                    </template>
                </el-dialog>
            </el-card>
        </el-main>
    </el-container>
</template>

<script>

export default {
    name: 'ChengquanProductInfocategory',
    data() {
        return {
            fullscreenshow: false,
            emptyText: '暂无数据',
            currentPage: 1,
            pageSize: 10,
            tableData: [],
            totals: null,
            serloading: false,
            productId: '',
            type: '',
            // 新增
            dialogAdd: false,
            ruleForm: {
                type: '',
            },
            rules: {
                type: [{
                    required: true,
                    message: '请输入品类名称',
                    trigger: 'blur'
                }],
            },
        };

    },
    created() {
        if (document.body.clientWidth < 768) {
            this.fullscreenshow = true;
        } else {
            this.fullscreenshow = false;
        }

        this.productId = this.$TOOL.data.get('DATA_SELECTEED');
        // 获取列表
        this.chengquanProductInfo();
    },
    watch: {
        '$store.state.dataSelected'(newval) {
            if (this.productId != newval) {
                this.productId = newval;
                // 获取列表
                this.chengquanProductInfo();
            }
        }
    },
    methods: {
        // 获取列表
        chengquanProductInfo() {
            this.serloading = true;
            this.$HTTP.post(
                'chengquanProductType/query',
                {
                    productId: this.productId,
                    pageNumber: this.currentPage,
                    pageSize: this.pageSize,
                    type: this.type,
                },
                response => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.data.code == 0) {
                            console.log(response.data.data)
                            this.tableData = response.data.data;
                            this.totals = response.data.data.total;
                            this.serloading = false;
                        } else {
                            this.$message.error(response.data.msg);
                        }
                    } else {
                        console.log(response.message);
                    }
                }
            );
        },
        // 搜索
        jouShare() {
            this.currentPage = 1;
            // 获取列表
            this.chengquanProductInfo();
        },

        // 删除
        deteleClk(row) {
            this.$HTTP.post(
                'chengquanProductType/delete', {
                type: row,
                productId: this.productId
            },
                response => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.data.code == 0) {
                            this.$message.success(response.data.msg);
                            // 获取列表
                            this.chengquanProductInfo();
                        } else {
                            this.$message.error(response.data.msg);
                        }
                    } else {
                        console.log(response.message);
                    }
                }
            );
        },

        // 新增
        submitClick(formName) {

            this.$refs[formName].validate(valid => {
                if (valid) {
                    this.$HTTP.post(
                        'chengquanProductType/add', {
                        type: this.ruleForm.type
                    }, response => {
                        if (response.status >= 200 && response.status < 300) {
                            if (response.data.code == 0) {
                                this.dialogAdd = false;
                                this.$message.success(response.data.msg);
                                this.resetClick(formName);
                                // 获取列表
                                this.chengquanProductInfo();
                            } else {
                                this.$message.error(response.data.msg);
                            }
                        } else {
                            console.log(response.message);
                        }
                    }
                    );
                } else {
                    return false;
                }
            });

        },
        // 重置
        resetClick(name) {
            this.$refs[name].resetFields();
        },
        // 分页
        handleSizeChange(val) {
            this.currentPage = 1;
            this.pageSize = val;
            // 获取列表
            this.chengquanProductInfo();
        },
        handleCurrentChange(val) {
            this.currentPage = val;
            // 获取列表
            this.chengquanProductInfo();
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.el-input-number {
    width: 100%;
}

.readbox {
    display: inline-block;

    span {
        margin-right: 10px;
        font-size: 16px;
    }
}

.elel {
    display: flex;
    align-items: center;
}
</style>
